<template>
  <div class="content_block">
    <div class="top">
      <b>客户服务记录</b>
      <template v-if="!isDisabled">
        <div class="btnContainer" v-if="isEditing">
          <el-button type="default" size="mini"  @click="handleCancel" v-if="!isShowHead">取消</el-button>
          <el-button type="primary" size="mini" @click="handleSave" v-if="!isShowHead">保存</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate" v-else>保存</el-button>
        </div>
        <div class="btnContainer" v-else>
          <el-button type="primary" plain icon="el-icon-edit" size="mini" @click="handleEdit">编辑</el-button>
        </div>
      </template>
    </div>
    <vxe-toolbar ref="xToolbar" v-if="this.isEditing">
      <template #buttons>
        <el-button type="primary" size="mini"  @click="handleAdd">新增</el-button >
      </template>
    </vxe-toolbar>
    <TableWithPagination
      :tableData="targetLists"
      :columnList="columnList"
      rowKey="index"
      class="product_list"
      :validRules="validRules"
      :editConfig="{trigger: 'munal', mode: 'cell', showStatus: true}"
    >
    </TableWithPagination>
    <SelectDevice ref="JZSelectDevice" @handleUpdateDevice="updateDevice"></SelectDevice>
  </div>
</template>

<script>
import CustomerApi from '@/api/customer';
import SelectDevice from './selectDevice.vue';
export default {
  name: '',
  props: {
    serviceLists: {
      type: Array,
      default () {
        return [];
      },
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    isShowHead: {
      type: Boolean,
      default () {
        return false;
      },
    },
  },
  watch: {
    isShowHead: {
      handler (val) {
        if (val) {
          this.isEditing = this.isShowHead;
        }
      },
      immediate: true,
      deep: true,
    },
    serviceLists: {
      handler (val) {
        if (val && val.length) {
          val = JSON.parse(JSON.stringify(val));
          this.targetLists = val.map((i, index)=>({
            ...i,
            index,
          }));
        }
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    SelectDevice,
  },
  methods: {
    handleAdd () {
      this.targetLists.push({
        operateType: '1',
        hospitalId: '',
        hospitalName: '',
        tenantServiceCapacityRecordDetailRespDTOs: [
          {
            hospitalId: '',
            hospitalName: '',
            deviceId: '',
            deviceName: '',
            deviceModel: '',
            deviceManufacturer: '',
            operateType: '1',
          },
        ],
      });
      this.targetLists = this.targetLists.map((i, index)=>({
        ...i,
        index,
      }));
    },
    updateDevice (lists) {
      let currentInfo = this.targetLists[this.currentIndex];
      let currentLists = currentInfo.tenantServiceCapacityRecordDetailRespDTOs;
      let currentDeviceIdLists = currentLists.filter(i=>i.deviceId).map(i => i.deviceId);
      let targetDeviceIdLists = lists.map(i=>i.deviceId);
      for (let i = 0; i < lists.length; i++) {
        const info = lists[i];
        if (!currentDeviceIdLists.includes(info.deviceId)) {
          currentLists.push({
            ...info,
            operateType: '1',
          });
        }
      }
      currentLists = currentLists.filter(i=>!i.deviceId || targetDeviceIdLists.includes(i.deviceId));
      currentInfo.tenantServiceCapacityRecordDetailRespDTOs = currentLists;
      this.targetLists.splice(this.currentIndex, 1, currentInfo);
    },
    handleDelete (currentIndex, index) {
      let targetLists = JSON.parse(JSON.stringify(this.targetLists));
      targetLists[index].tenantServiceCapacityRecordDetailRespDTOs.splice(currentIndex, 1);
      if (targetLists[index].tenantServiceCapacityRecordDetailRespDTOs && !targetLists[index].tenantServiceCapacityRecordDetailRespDTOs.length) {
        targetLists.splice(index, 1);
      }
      this.targetLists = targetLists.map((i, index)=>({
        ...i,
        index,
      }));
    },
    handleSelectDevice (index) {
      this.currentIndex = index;
      let recordLists = (this.targetLists[index].tenantServiceCapacityRecordDetailRespDTOs || []).filter(i => i.deviceId);
      this.$refs.JZSelectDevice.idList = (recordLists || []).map(i=>i.deviceId);
      this.$refs.JZSelectDevice.selectedLists = recordLists || [];
      this.$refs.JZSelectDevice.initPage();
    },
    handleEdit () {
      this.isEditing = true;
    },
    handleCancel () {
      this.targetLists = (this.serviceLists || []).map((i, index)=>({
        ...i,
        index,
      }));
      this.isEditing = false;
    },
    handleSave () {
      if (this.handleValidateServiceRecords()) {
        // 这里处理数据 将展示内容 根据 数值对比 直接转换成想要的数据类型
        let targetLists = this.handleDetailWithTargetLists();
        this.$emit('updateRecordLists', targetLists);
        this.isEditing = false;
      }
    },
    handleUpdate () {
      if (this.handleValidateServiceRecords()) {
        // 这里处理数据 将展示内容 根据 数值对比 直接转换成想要的数据类型
        let targetLists = this.handleDetailWithTargetLists();
        this.$emit('updateRecordLists', targetLists);
      }
    },
    handleDetailWithTargetLists () {
      let targetLists = JSON.parse(JSON.stringify(this.targetLists));
      // 更新数据
      return targetLists;
    },
    handleValidateServiceRecords () {
      let serviceLists = this.targetLists || [];
      if (!(serviceLists && serviceLists.length)) {
        this.$message.error('客服服务记录必填');
        return false;
      }
      let isError = serviceLists.every(i => i.hospitalName);
      if (!isError) {
        this.$message.error('医院必填');
        return isError;
      }
      let currentLists = serviceLists.map(i=>i.tenantServiceCapacityRecordDetailRespDTOs).flat();
      isError = currentLists.every(i => i.deviceName);
      if (!isError) {
        this.$message.error('设备名称必填');
        return isError;
      }
      isError = currentLists.every(i => i.deviceModel);
      if (!isError) {
        this.$message.error('设备型号必填');
        return isError;
      }
      isError = currentLists.every(i => i.deviceId || (!i.deviceId && i.deviceManufacturer));
      if (!isError) {
        this.$message.error('设备厂家必填');
        return isError;
      }
      return isError;
    },
    querySearchHospitalAsync (queryString, cb) {
      let currentLists = [];
      if (queryString) {
        CustomerApi.getCustomerList({
          name: queryString,
          status: 'EFFECTIVE',
          pageNum: 1,
          pageSize: 10,
          isPermissions: false,
          isIndate: true,
          customerSource: this.source,
        }).then(res => {
          currentLists = res?.body?.records || [];
          cb(currentLists);
        }).catch(() => {
          cb(currentLists);
        });
      } else {
        cb(currentLists);
      }
    },
    handleChangeHospital (info, row) {
      row.hospitalName = info.name;
      row.hospitalId = info.id;
    },
  },
  computed: {
    columnList () {
      let currentLists = [
        {
          tooltip: true,
          label: '医院',
          minWidth: '180px',
          prop: 'hospitalName',
          editRender: {
            name: 'input',
            attrs: { type: 'text', placeholder: '请选择医院' },
          },
          render: (h, {row}) => {
            let result = !this.isEditing ? [row.hospitalName || '--'] : [
              <el-input style='width:100%;margin:5px 0;' size='mini' v-model={row.hospitalName} placeholder='请输入医院'></el-input>,
            ];
            return h('div', result);
          },
        },
        {
          tooltip: true,
          label: '设备名称',
          minWidth: '180px',
          prop: 'deviceName',
          editRender: {
            name: 'input',
            attrs: { type: 'text', placeholder: '请输入设备名称' },
          },
          render: (h, {row}) => {
            let result = [];
            let temp = null;
            if (!this.isEditing) {
              temp = (row.tenantServiceCapacityRecordDetailRespDTOs || []).map(i => i.deviceName || '--');
            } else {
              temp = (row.tenantServiceCapacityRecordDetailRespDTOs || []).map(i => <el-input style='width:100%;margin:5px 0;' size='mini' disabled={!!i.deviceId} v-model={i.deviceName} placeholder='请输入设备名称'></el-input>);
            }
            result.push((
              <div class='product_list_cell'>
                <div class='actionContainer'>{temp}</div>
              </div>
            ));

            return h('div', result);
          },
        },
        {
          tooltip: true,
          label: '设备型号',
          minWidth: '180px',
          prop: 'deviceModel',
          editRender: {
            name: 'input',
            attrs: { type: 'text', placeholder: '请输入设备型号' },
          },
          render: (h, {row}) => {
            let result = [];
            let temp = null;
            if (!this.isEditing) {
              temp = (row.tenantServiceCapacityRecordDetailRespDTOs || []).map(i => i.deviceModel || '--');
            } else {
              temp = (row.tenantServiceCapacityRecordDetailRespDTOs || []).map(i => <el-input style='width:100%;margin:5px 0;' disabled={!!i.deviceId} size='mini' v-model={i.deviceModel} placeholder='请输入设备型号'></el-input>);
            }
            result.push((
              <div class='product_list_cell'>
                <div class='actionContainer'>{temp}</div>
              </div>
            ));

            return h('div', result);
          },
        },
        {
          tooltip: true,
          label: '设备厂家',
          minWidth: '180px',
          prop: 'deviceManufacturer',
          editRender: {
            name: 'input',
            attrs: { type: 'text', placeholder: '请输入设备厂家' },
          },
          render: (h, {row}) => {
            let result = [];
            let temp = null;
            if (!this.isEditing) {
              temp = (row.tenantServiceCapacityRecordDetailRespDTOs || []).map(i => i.deviceManufacturer || '--');
            } else {
              temp = (row.tenantServiceCapacityRecordDetailRespDTOs || []).map(i => <el-input style='width:100%;margin:5px 0;' disabled={!!i.deviceId} size='mini' v-model={i.deviceManufacturer} placeholder='请输入设备厂家'></el-input>);
            }
            result.push((
              <div class='product_list_cell'>
                <div class='actionContainer'>{temp}</div>
              </div>
            ));
            return h('div', result);
          },
        },
        {
          tooltip: true,
          label: '操作',
          minWidth: '180px',
          editRender: {
            name: 'input',
            attrs: { type: 'text', placeholder: '请输入设备厂家' },
          },
          render: (h, {row, index}) => {
            let result = [];
            let temp = (row.tenantServiceCapacityRecordDetailRespDTOs || []).map((i, currentIndex) => <span class='table-action table-action-delete' style='margin:5px 0;' onClick={()=>this.handleDelete(currentIndex, index)}>删除</span>);
            result.push((
              <div class='product_list_cell'>
                <div class='actionContainer actionContainer01'>{temp}</div>
                <span class='table-action' onClick={()=>this.handleSelectDevice(index)}>选择设备</span>
              </div>
            ));
            return h('div', result);
          },
        },
      ];
      if (!this.isEditing) {
        currentLists.splice(4, 1);
      }
      return currentLists;
    },
    validRules () {
      return {
        hospitalName: [
          { required: true, message: '医院必须填写' },
          { min: 3, max: 50, message: '名称长度在 3 到 50 个字符' },
        ],
        deviceName: [
          { required: true, message: '请输入设备名称' },
        ],
        deviceModel: [
          { required: true, message: '请输入设备型号' },
        ],
        deviceManufacturer: [
          { required: true, message: '请输入设备厂家' },
        ],
      };
    },
  },
  data () {
    return {
      currentIndex: 0,
      isEditing: false,
      targetLists: [],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.content_block{
  padding: 0 20px 20px 20px;
  min-height: calc(100vh - 420px);
  .top{
    height: 40px;
    line-height: 40px;
    display: flex;
    .btnContainer{
      flex: 1;
      text-align:right;
    }
  }
}
/deep/ .warningLabel{
  width: 110px;
  text-align: right;
  display: inline-block;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0;
  text-align: right;
  line-height: 20px;
}
/deep/ .warningContent{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1F2733;
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
  display: flex;
  flex-direction: column;
}
/deep/ .product_list_cell {
  display: flex;
  .actionContainer{
    margin-right: 10px;
    padding-right: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    &.actionContainer01{
      border-right: 1px solid #EBEDF0;
      max-width:100px;
    }
  }
  span{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/deep/.vxe-table--render-default.size--small .vxe-body--column.col--ellipsis{
  // height: auto;
  min-height: 40px;
    & > .vxe-cell{
    max-height: auto;
  }
}
/deep/ .vxe-table--render-default.size--small .vxe-body--column.col--ellipsis>.vxe-cell{
  max-height: 4000px!important;
}
/deep/ .vxe-table--render-default .vxe-body--column.col--ellipsis>.vxe-cell {
  max-height: auto!important;
}
</style>
